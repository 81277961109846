<template>
  <div>
    <vs-row class="mb-4">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <h3 class="main-headd">SR Dashboard</h3>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="10">
        <vs-row vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="flex-start">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="2.5">
            <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                City
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect v-model="selected_city" :multiple="true" :options="tree_City" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-col>
          <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="2.5"
        >
        <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                GM
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect v-model="selected_gm_cc" slot="" :multiple="true" :options="city_with_gm" />
              </vs-dropdown-menu>
        </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="2.5"
        >
          <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
                <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                  Spoc
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                  <treeselect v-model="selected_spoc" :multiple="true" :options="city_with_sr" />
                </vs-dropdown-menu>
          </vs-dropdown>
          
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.6">
            <vs-button color="dark" icon="search" type="gradient" @click="searchByDate">
            </vs-button>
            &nbsp;
            <vs-button color="dark" type="gradient" icon="close" @click="refreshData" title="Refresh"></vs-button>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row style="margin: 2% 0 2% 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>Search Criteria:</b>
      </vs-col>
      <vs-col style="overflow-y: scroll" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
        <div v-for="(chip, index) in searchCriteria" :key="index">
          <vs-chip v-if="chip !== 'divider'" closable @click="removechip(chip)" color="#0044ba">
            <b>{{ chip }}</b>
          </vs-chip>
          <div v-else>
            <div class="vl"></div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <div v-if="top_spocs_summarys.length != 0">
      <div class="top_spoc_heading">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <h3 class="card-header">Top 5 Spocs</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="10"
          >
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              style="width: 240px"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="topSpocdateRange"
            >
            </date-range-picker>
            &nbsp;
            <vs-button
              color="dark"
              icon="search"
              type="gradient"
              @click="searchTopSpocsByDate"
            >
            </vs-button>
            &nbsp;
            <vs-button
              color="dark"
              type="gradient"
              icon="close"
              @click="refreshTopSpocsData"
              title="Refresh"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <top-spocs :top_spocs_summarys="top_spocs_summarys"></top-spocs>
    </div>
    <overall-trends
      :overall_trends="overall_trends"
      v-if="overall_trends.length > 0"
    ></overall-trends>
    <div
      v-if="
        key_metrics.length > 0 || Object.keys(visit_calls_object).length > 0
      "
    >
      <vs-card>
        <!-- <h3 class="card-header">KEY METRICS</h3> -->
        <div class="key_matric_heading">
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <h3 class="card-header">KEY METRICS</h3>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="10"
            >
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
                :minDate="null"
                :maxDate="null"
                :singleDatePicker="false"
                :timePicker="false"
                style="width: 240px"
                :timePicker24Hour="true"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="false"
                v-model="KeyMetricsdateRange"
              >
              </date-range-picker>
              &nbsp;
              <vs-button
                color="dark"
                icon="search"
                type="gradient"
                @click="searchKeyMetricsByDate"
              >
              </vs-button>
              &nbsp;
              <vs-button
                color="dark"
                type="gradient"
                icon="close"
                @click="refreshKeyMetricsData"
                title="Refresh"
              ></vs-button>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 2%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-lg="2"
            vs-sm="6"
            vs-xs="5"
            style="margin-right: 8px"
            v-for="(key_metric, index) in key_metrics"
            :key="index"
          >
            <key-metric-card
              :key_metric="key_metric"
              @total-with-city-enrollment="getCityEnrollment"
            >
            </key-metric-card>
          </vs-col>
          <vs-col
            vs-justify="space-between"
            vs-align="center"
            vs-type="flex"
            vs-lg="2"
            vs-sm="6"
            vs-xs="5"
            v-for="(visit_calls, key) in visit_calls_object"
            :key="visit_calls.visits_key"
            class="ml-4"
          >
            <visitors-card
              :visits_key="key"
              :visits_value="visit_calls"
              @show-calls-city-summary="getCallsCitySummary"
            ></visitors-card>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>
    


    <div
      style="margin-top: 2%"
      v-if="
        evaluation_values.length != 0 || evaluation_time_periods.length != 0
      "
    >
      <vs-card>
        <h3 class="card-header">GAME PLAN STATS</h3>
        <vs-row
          vs-justify="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 3%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-around"
            vs-lg="2"
            vs-sm="4"
            vs-xs="5"
            v-for="(evaluation_value, key, index) in evaluation_values"
            :key="index"
          >
            <evaluation-card
              :evaluation_value="evaluation_value"
              :evaluation_key="key"
              @show-evaluation-details="getEvaluationDetails"
            >
            </evaluation-card>
          </vs-col>
        </vs-row>

        <!-- Evaluation -->
        <vs-row>
          <vs-col vs-type="flex" vs-w="7">
            <h3 style="margin-bottom: 4px">
              GAME PLAN STATS TIME PERIOD DETAILS
            </h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-w="5"
            vs-justify="flex-end"
            style="margin-bottom: 5px"
          >
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              style="width: 200px; margin-top: 3px"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="dateRange"
            >
            </date-range-picker>
            &nbsp;
            <vs-button
              color="primary"
              icon="search"
              @click="searchEvaluationByDate"
            >
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row
          vs-justify="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 3%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-around"
            vs-lg="2"
            vs-sm="4"
            vs-xs="5"
            v-for="(
              evaluation_time_period, key, index
            ) in evaluation_time_periods"
            :key="index"
          >
            <evaluation-time-period-card
              :evaluation_time_period="evaluation_time_period"
              :evaluation_time_period_key="key"
              @show-evaluation-time-period-details="
                getEvaluationTimePeriodDetails
              "
            >
            </evaluation-time-period-card>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>
    <!-- Bridge Course -->
    <div
      v-if="
        eligibility_counts.length != 0 ||
        eligibility_time_period_counts.length != 0
      "
    >
      <vs-card>
        <h3 class="card-header">BRIDGE COURSE</h3>
        <vs-row
          vs-justify="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 3%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-around"
            vs-lg="2"
            vs-sm="4"
            vs-xs="5"
            v-for="(eligibility_count, key, index) in eligibility_counts"
            :key="index"
          >
            <bridge-course-card
              :eligibility_count="eligibility_count"
              :eligibility_key="key"
              @show-eligibility-details="getEligibilityDetails"
            ></bridge-course-card>
          </vs-col>
        </vs-row>

        <!-- Time period Details -->
        <vs-row>
          <vs-col vs-type="flex" vs-w="7">
            <h3 style="margin-bottom: 4px">
              BRIDGE COURSE TIME PERIOD DETAILS
            </h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-w="5"
            vs-justify="flex-end"
            style="margin-bottom: 5px"
          >
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              style="width: 200px; margin-top: 3px"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="dateRangeEligibility"
            >
            </date-range-picker>
            &nbsp;
            <vs-button
              color="primary"
              icon="search"
              @click="searchEligibilityByDate"
            >
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row
          vs-justify="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 3%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-around"
            vs-lg="2"
            vs-sm="4"
            vs-xs="5"
            v-for="(
              eligibility_time_period_count, key, index
            ) in eligibility_time_period_counts"
            :key="index"
          >
            <bridge-course-time-period-card
              :eligibility_time_period_count="eligibility_time_period_count"
              :eligibility_time_period_key="key"
              @show-eligibility-time-period-details="
                getEligibilityTimePeriodDetails
              "
            >
            </bridge-course-time-period-card>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>
    <vs-card v-if="student_details.length != 0" class="mt-3">
      <student-connect-table
        :student_details="student_details"
      ></student-connect-table>
    </vs-card>
    <!-- <div style="margin-top: 3%;">
      <h3>IMA Registration</h3>
      <vs-row style="margin-top: 2%;">
        <vs-col vs-type="flex" vs-w="2" v-for="(imr_registation_detail, index) in imr_registation_details" :key="index">
          <ima-ragistation :imr_registation_detail="imr_registation_detail"></ima-ragistation>
        </vs-col>
      </vs-row>
    </div>
    <div style="margin-top: 3%;">
      <h3>MAcc/Ms</h3>
      <vs-row style="margin-top: 2%;">
        <vs-col vs-type="flex" vs-w="2" v-for="(mass_value, index) in mass_values" :key="index">
          <mass-card :mass_value="mass_value"></mass-card>
        </vs-col>
      </vs-row>
    </div>
    <div style="margin-top: 3%;">
      <h3>Jobs/Placement</h3>
      <vs-row style="margin-top: 2%;">
        <vs-col vs-type="flex" vs-w="2" v-for="(placement_value, index) in placement_values" :key="index">
          <placement-card :placement_value="placement_value"></placement-card>
        </vs-col>
      </vs-row>
    </div>
    <div style="margin-top: 3%;"> -->
    <!-- <h3>Net Inquiry Metrics(MAcc/MS)</h3>
      <vs-row style="margin-top: 2%;">
        <vs-col vs-type="flex" vs-w="2" v-for="(net_inquiry_metric_value, index) in net_inquiry_metric_values"
          :key="index">
          <net-inquiry-metric-card :net_inquiry_metric_value="net_inquiry_metric_value"></net-inquiry-metric-card>
        </vs-col>
      </vs-row>
    </div> -->
    <enrollments-popup
      :enrollments_popup="enrollments_popup"
      :city_wises_enrollments="city_wises_enrollments"
      :date_props="date_props"
      :team="teamm"
      @close-enrollments-popup="
        enrollments_popup = false;
        selected_course = '';
      "
    >
    </enrollments-popup>

    <visit-count-popup
      :visits_city_summary_popup="visits_city_summary_popup"
      :visits_city_wises="visits_city_wises"
      :date_props="date_props"
      :team="teamm"
      @close-visit-count-popup="visits_city_summary_popup = false"
    ></visit-count-popup>

    <ConnectedCallsPopup
      :calls_city_summary_popup="calls_city_summary_popup"
      :calls_city_wises="calls_city_wises"
      :date_props="date_props"
      @close-connected-call-popup="calls_city_summary_popup = false"
      :team="teamm"
    ></ConnectedCallsPopup>
  </div>
</template>

<script>
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import StudentConnectTable from "../../components/SrDashboard/StudentConnectTable.vue";
// import constants from "../../../constants.json";
import EvaluationCard from "../../components/SrDashboard/EvaluationCard.vue";
import EvaluationTimePeriodCard from "../../components/SrDashboard/EvaluationTimePeriodCard.vue";
import BridgeCourseCard from "../../components/SrDashboard/BridgeCourseCard.vue";
import BridgeCourseTimePeriodCard from "../../components/SrDashboard/BridgeCourseTimePeriodCard.vue";
// import ImaRagistation from "../../components/SrDashboard/ImaRagistationCard.vue"
// import MassCard from "../../components/SrDashboard/MassCard.vue"
// import PlacementCard from "../../components/SrDashboard/PlacementCard.vue"
// import NetInquiryMetricCard from "../../components/SrDashboard/NetInquiryMetricCard.vue"
import ApiServices from "../../ApiServices";
import TopSpocs from "../../components/SrDashboard/SRTopSpocs.vue";
import OverallTrends from "../../components/CmDashboard/OverallTrends.vue";
import KeyMetricCard from "../../components/CmDashboard/keyMetricCard.vue";
import VisitorsCard from "../../components/CmDashboard/VisitorsCard.vue";
import ConnectedCallsPopup from "../../components/CmDashboard/ConnectedCallsPopup.vue";
import EnrollmentsPopup from "../../components/CmDashboard/EnrollmentsPopup.vue";
import VisitCountPopup from "../../components/CmDashboard/VisitCountPopup.vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    Treeselect,
    StudentConnectTable,
    EvaluationCard,
    EvaluationTimePeriodCard,
    BridgeCourseCard,
    BridgeCourseTimePeriodCard,
    // ImaRagistation,
    // MassCard,
    // PlacementCard,
    // NetInquiryMetricCard,
    DateRangePicker,
    TopSpocs,
    OverallTrends,
    KeyMetricCard,
    VisitorsCard,
    ConnectedCallsPopup,
    EnrollmentsPopup,
    VisitCountPopup,
  },
  data: () => ({
    teamm:"SR", 
    city_with_sr : [],
    city_with_gm: [],
    selected_gms:[],
    selected_gm_cc:[],
    searchCriteria: [],
    selected_city: [],
    spoc_data:[],
    cities:[],
    tree_City:[],
    open: false,
    dateRange: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    KeyMetricsdateRange: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    topSpocdateRange:{
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    dateRangeEligibility: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    student_details: [],
    visits_city_summary_popup: false,
    visits_city_wises: [],
    evaluation_values: [],
    evaluation_time_periods: [],
    eligibility_counts: [],
    key_metrics: [],
    selected_course: "",
    eligibility_time_period_counts: [],
    visit_calls_object: {},
    top_spocs_summarys: [],
    overall_trends: [],
    calls_city_wises: [],
    calls_city_summary_popup: false,
    city_wises_enrollments: [],
    enrollments_popup: false,
    imr_registation_details: [
      {
        type: "IMA Registration Applicable",
        count: "789",
      },
      {
        type: "Registration Done",
        count: "256",
      },
      {
        type: "Registration Done %",
        count: "26.5",
      },
      {
        type: "Missed Registration",
        count: "45",
      },
      {
        type: "Missed Registration %",
        count: "54",
      },
    ],
    mass_values: [
      {
        type: "Leads shared",
        count: "525",
      },
      {
        type: "Enrolled",
        count: "426",
      },
      {
        type: "Enrolled %",
        count: "45",
      },
    ],
    placement_values: [
      {
        type: "Freshers (B.Com only)",
        count: "525",
      },
      {
        type: "Lateral",
        count: "426",
      },
      {
        type: "CA Inter",
        count: "250",
      },
      {
        type: "CA",
        count: "65",
      },
      {
        type: "Alumni",
        count: "98",
      },
    ],
    net_inquiry_metric_values: [
      {
        type: "Freshers (B.Com only)",
        count: "525",
      },
      {
        type: "Lateral",
        count: "426",
      },
      {
        type: "CA Inter",
        count: "250",
      },
      {
        type: "CA",
        count: "65",
      },
      {
        type: "Alumni",
        count: "98",
      },
    ],
    selected_gm: "",
    selected_spoc: "",
    course_wises: [],
    date_props: [],
  }),
  mounted() {

    this.getSpocsBasedOnCC();
    this.getStudentConnectStats();
    this.getEvaluetionCount();
    let startDateEligibility = moment(
      this.dateRangeEligibility.startDate,
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    let endDateEligibility = moment(
      this.dateRangeEligibility.endDate,
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    this.getEvaluationTimePeriodCount(startDate, endDate);
    this.getEligibilityCount();
    this.getTimeperiodEligibility(startDateEligibility, endDateEligibility);
    this.getTopSpocs();
    this.getOverallTrendsData();
    this.getEnrollmentData();
    this.getVisitsData();
  },
  methods: {
    removeDuplicates(arr) {
        this.selected_gms = arr.filter((item, index) => arr.indexOf(item) === index);
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      this.selected_city.forEach(element => {
          this.searchCriteria.push(element);  
      });
      this.selected_gm_cc.forEach(element => {
          this.searchCriteria.push(element);  
      });

      this.selected_spoc.forEach(element => {
          this.searchCriteria.push(element);  
      });

    },
    removechip(chip) {
      let chipPlacement = "";
      
      this.selected_city.forEach(element => {
        if (element === chip) {
          chipPlacement = "city";
        }
      });
      this.selected_gm_cc.forEach(element => {
        if (element === chip) {
          chipPlacement = "gm";
        }
      });
      this.selected_spoc.forEach(element => {
        if (element === chip) {
          chipPlacement = "spoc";
        }
      });
      switch (chipPlacement) {
        case "city":
          this.selected_city.splice(
            this.selected_city.indexOf(chip),
            1
        );
          break;
        case "gm":
        this.selected_gm_cc.splice( this.selected_gm_cc.indexOf(chip),
            1);
          break;
        case "spoc":
        this.selected_spoc.splice(
          this.selected_spoc.indexOf(chip),
          1
        );
      }
    },
    searchByDate(){
      this.getSpocsBasedOnCC();
      this.getStudentConnectStats();
      this.getEvaluetionCount();
      this.getEligibilityCount();
      this.getTopSpocs();
      this.getOverallTrendsData();
      this.getEnrollmentData();
      this.getVisitsData();
    },
    getSpocsBasedOnCC() {
      let url = `https://milesdashboards.2x2.ninja/api/getSrTeamSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.spoc_data = response.data
          console.log("getSpocsBasedonCC", response.data)
          this.cities = [
            ...new Set(response.data.map((item) => item.city)),
          ].sort();
          this.getTreeCity();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getTreeCity() {
      this.tree_City = []
      if (this.cities.length != 0) {
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        for (let i = 0; i < this.cities.length; i++) {
          const element = this.cities[i];
          const obj = {
            id: element,
            label: element,
          };
          if(city_options.includes(element)){
           this.tree_City.push(obj);
          }
        }
        console.log("treeDetails", this.tree_City);
      }
    },
    getVisitsData() {
      let gm = this.selected_gms
      this.$vs.loading();
      let startDate = moment(
        this.KeyMetricsdateRange.startDate,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      let endDate = moment(
        this.KeyMetricsdateRange.endDate,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      let obj = {
        spoc: this.selected_spoc.toString(),
        gm: encodeURIComponent(gm.toString()),
        team: this.teamm,
        city: this.selected_city.toString()
      };
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-visits-calls/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallVisitsCalls?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getVisitsData(startDate, endDate, obj)
        .then((response) => {
          let visits = response.data;
          // console.log("visits", visits);
          let check_if_array = Array.isArray(visits);
          let visit_call = {};
          if (check_if_array) {
            for (let i = 0; i < visits.length; i++) {
              visit_call = visits[0];
              // this.key_metrics.push(element);
              console.log("key metrics object", visit_call);
            }
            this.visit_calls_object = {
              connected_calls: {
                count: visit_call["connected_calls"],
                utilization: visit_call["call_utilization"],
              },
              visit_count: {
                count: visit_call["visit_count"],
                utilization: visit_call["visit_utilization"],
              },
            };
            // console.log("visit_calls_object", this.visit_calls_object);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getEnrollmentData() {
      let gm = this.selected_gms
      this.course_wises = [];
      this.key_metrics = [];
      this.$vs.loading();
      let startDate = moment(
        this.KeyMetricsdateRange.startDate,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      let endDate = moment(
        this.KeyMetricsdateRange.endDate,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      let obj = {
        spoc: this.selected_spoc.toString(),
        gm: encodeURIComponent(gm.toString()),
        team: this.teamm,
        city: this.selected_city
      };
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // // let url = `http://milesapi.bulbot.com/api/performance-dashboard/enrollments-overall-count/?end-date=${endDate}&start-date=${startDate}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEnrollmentData(startDate, endDate, obj)
        .then((response) => {
          let enrollment = response.data;
          // console.log("enrollment", enrollment);
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
            for (let i = 0; i < enrollment.length; i++) {
              const element = enrollment[i];
              if (element.course == "Total enrollments") {
                this.key_metrics.push(element);
              } else if (element.course != "Total enrollments") {
                this.course_wises.push(element);
              } else {
                this.key_metrics = [];
                this.course_wises = [];
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getCallsCitySummary(val) {
      this.$vs.loading();
      let startDate = moment(this.KeyMetricsdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.KeyMetricsdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
        team: this.teamm,
        city: this.selected_city
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "connected_calls") {
        this.city_wises_enrollments = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/calls-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getCallsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm_name}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getCallsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.calls_city_wises = response.data;
            this.calls_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
      if (val == "visit_count") {
        this.city_wises_enrollments = [];
        this.calls_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.netenquiry_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/visits-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getVisitsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getVisitsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.visits_city_wises = response.data;
            this.visits_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getCityEnrollment(course) {
      // console.log("course--", course);
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      if (course == null) {
        this.selected_course = "";
      } else {
        this.selected_course = course;
      }
      // console.log("course2--", course);
      this.$vs.loading();
      let startDate = moment(this.KeyMetricsdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.KeyMetricsdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
        team: this.teamm,
        city: this.selected_city,
        course: this.selected_course,
      };
      if (this.selected_course != "") {
        this.date_props = {
          card_header:
            startDate + " - " + endDate + " | " + this.selected_course,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: this.selected_course,
        };
        // console.log("this.date_props course not empty", this.date_props)
      } else {
        this.date_props = {
          card_header: startDate + " - " + endDate,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: "",
        };
        // console.log("this.date_props course empty", this.date_props)
      }
      ApiServices.getCityEnrollment(startDate, endDate, obj)
        .then((response) => {
          // console.log("Find Table Data", response.data);
          this.city_wises_enrollments = response.data;
          this.enrollments_popup = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getTopSpocs() {
      this.$vs.loading();
      let startDate = moment(this.topSpocdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.topSpocdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      ApiServices.getTopSpocs(startDate, endDate, "SR" , this.selected_city)
        .then((response) => {
          // console.log("getTopSpocs", response.data)
          if (typeof response.data == "object") {
            this.top_spocs_summarys = response.data;
            console.log("getTopSpoc", this.top_spocs_summarys);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getOverallTrendsData() {
      console.log("gm", this.selected_gm);
      console.log("cm", this.selected_spoc);
      this.$vs.loading();
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-trend-summary/`;
      // if (this.selected_gm != null && this.selected_gm != "") {
      //   url += `?gm=${this.selected_gm}`;
      // } else if (this.selected_spoc != null && this.selected_spoc != "") {
      //   url += `?spoc=${this.selected_spoc}`;
      // }
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
      //     },
      //   })
      let gm = this.selected_gms
      this.overall_trends = []
      ApiServices.getOverallTrendsSummary(
        encodeURIComponent(gm.toString()),
        this.selected_spoc.toString(),
        "SR",
        this.selected_city.toString()
      )
        .then((response) => {
          console.log("getOverallTrendsSummary", response.data);
          this.overall_trends = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getStudentConnectStats() {
      this.$vs.loading();
      ApiServices.getStudentConnectStats()
        .then((response) => {
          this.student_details = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getStudentConnectDetails(level, type) {
      let type_header = {
        last_30_days: "Connected Last 30 Days",
        last_90_days: "Connected Last 90 Days",
        last_180_days: "Connected Last 180 Days",
        before_180_days: "Connected 180+ Days",
        not_connected: "Not Connected",
      };
      let level_day = {
        level: level,
        type: type,
        type_header: type_header[type],
      };
      this.$router.push({
        name: "StudentConnectDetails",
        params: { studentConnect: level_day },
      });
    },
    getEvaluetionCount() {
      this.$vs.loading();
      ApiServices.evaluationCount()
        .then((response) => {
          this.evaluation_values = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getEvaluationDetails(type, count) {
      let evaluation_header = {
        evaluation_submitted: "Evaluation Submitted Details",
        completed_evaluation: "Evaluation Completed Details",
        evaluation_initiated: "Evaluation Initiated Details",
        evaluation_not_initiated: "Evaluation Not Initiated Details",
        total_evaluations: "Total Evaluation Details",

        yet_to_send_doc_to_miles: "Yet to send education documents to Miles",
        yet_to_receive_evaluation_advice: "Yet to receive Evaluation Advice",
        yet_to_send_doc_to_agency: "Yet to send docs to evaluation agency",
        yet_to_receive_evaluation_report:
          "Yet to receive the evaluation report",
        yet_to_receive_NTS_advice: "Yet to receive NTS advice from Miles",
        yet_to_apply_for_NTS: "Yet to apply for NTS",
        yet_to_receive_NTS: "Yet to receive NTS",
        yet_to_apply_for_exams: "Yet to apply for exams",
        yet_to_pass_four_exams: "Yet to pass all 4 exams",
        yet_to_receive_license_advice:
          "Yet to receive license advice from Miles",
        license_app_WIP: "License application WIP",
        received_license: "Received CPA license",
        incompleted_docs:
          "Sent Education documents to Miles; Yet to receive Eligibility (Incomplete Documents)",
      };
      let evaluation_detail = {
        evaluation_type: type,
        evaluation_header: evaluation_header[type],
        evaluation_count: count,
      };
      this.$router.push({
        name: "EvaluationDetail",
        params: { evaluation_detail: evaluation_detail },
      });
      console.log("evaluation ");
    },
    getEvaluationTimePeriodCount(startDate, endDate) {
      this.evaluation_time_periods = [];
      this.$vs.loading();
      ApiServices.timeperiodEvaluationCount(startDate, endDate)
        .then((response) => {
          this.evaluation_time_periods = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    searchEvaluationByDate() {
      this.evaluation_time_periods = [];
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      this.getEvaluationTimePeriodCount(startDate, endDate);
    },
    searchEligibilityByDate() {
      this.eligibility_time_period_counts = [];
      let startDateEligibility = moment(
        this.dateRangeEligibility.startDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let endDateEligibility = moment(
        this.dateRangeEligibility.endDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      this.getTimeperiodEligibility(startDateEligibility, endDateEligibility);
    },
    searchKeyMetricsByDate() {
      this.key_metrics = [];
      this.visit_calls_object = [];
      this.getEnrollmentData();
      this.getVisitsData();
    },
    refreshKeyMetricsData() {
      this.KeyMetricsdateRange = {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
      };
      this.getEnrollmentData();
      this.getVisitsData();
    },
    searchTopSpocsByDate(){
      this.top_spocs_summarys = [];
      this.getTopSpocs();
    },
    refreshTopSpocsData() {
      this.topSpocdateRange ={
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    this.getTopSpocs();
    },
    getEvaluationTimePeriodDetails(type, count) {
      let evaluation_header = {
        evaluation_submitted: "Evaluation Submitted Details",
        completed_evaluation: "Evaluation Completed Details",
        evaluation_initiated: "Evaluation Initiated Details",
        evaluation_not_initiated: "Evaluation Not Initiated Details",
        total_evaluations: "Total Evaluation Details",

        yet_to_send_doc_to_miles: "Yet to send education documents to Miles",
        yet_to_receive_evaluation_advice: "Yet to receive Evaluation Advice",
        yet_to_send_doc_to_agency: "Yet to send docs to evaluation agency",
        yet_to_receive_evaluation_report:
          "Yet to receive the evaluation report",
        yet_to_receive_NTS_advice: "Yet to receive NTS advice from Miles",
        yet_to_apply_for_NTS: "Yet to apply for NTS",
        yet_to_receive_NTS: "Yet to receive NTS",
        yet_to_apply_for_exams: "Yet to apply for exams",
        yet_to_pass_four_exams: "Yet to pass all 4 exams",
        yet_to_receive_license_advice:
          "Yet to receive license advice from Miles",
        license_app_WIP: "License application WIP",
        received_license: "Received CPA license",
        incompleted_docs:
          "Sent Education documents to Miles; Yet to receive Eligibility (Incomplete Documents)",
      };
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      let time_period_detail = {
        evaluation_type: type,
        evaluation_count: count,
        evaluation_header: evaluation_header[type],
        start_date: startDate,
        end_data: endDate,
      };
      this.$router.push({
        name: "EvaluationTimePeriodDetails",
        params: { time_period_detail: time_period_detail },
      });
      // console.log("getEvaluationTimePeriodDetails", type, startDate, endDate)
    },
    getEligibilityCount() {
      this.$vs.loading();
      ApiServices.eligibilityCount()
        .then((response) => {
          this.eligibility_counts = response.data;
          console.log("this.eligibility_counts", this.eligibility_counts);
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getEligibilityDetails(type, count) {
      let eligibility_header = {
        missed_enrollment: "Missed Eligibility ",
        completed_enrollment: "Completed Eligibility ",
        total_eligibility: "Total Eligibility",
        bridge_course_exam_completed: "Bridge Course Exam Completed",
        bridge_course_exam_pending: "Bridge Course Exam Pending",
        bridge_course_license_completed: "Bridge Course License Completed",
        bridge_course_license_pending: "Bridge Course License Pending",
      };
      let eligibility_detail = {
        eligibility_type: type,
        eligibility_header: eligibility_header[type],
        eligibility_count: count,
      };
      this.$router.push({
        name: "BridgeCourseDetail",
        params: { eligibility_detail: eligibility_detail },
      });
    },
    getTimeperiodEligibility(startDate, endDate) {
      this.$vs.loading();
      ApiServices.timeperiodEligibility(startDate, endDate)
        .then((response) => {
          this.eligibility_time_period_counts = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getEligibilityTimePeriodDetails(type, count) {
      let eligibility_header = {
        missed_enrollment: "Missed Eligibility ",
        completed_enrollment: "Completed Eligibility ",
        total_eligibility: "Total Eligibility",
        bridge_course_exam_completed: "Bridge Course Exam Completed",
        bridge_course_exam_pending: "Bridge Course Exam Pending",
        bridge_course_license_completed: "Bridge Course License Completed",
        bridge_course_license_pending: "Bridge Course License Pending",
      };
      let startDateEligibility = moment(
        this.dateRangeEligibility.startDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let endDateEligibility = moment(
        this.dateRangeEligibility.endDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let eligibility_time_period_detail = {
        eligibility_type: type,
        eligibility_count: count,
        eligibility_header: eligibility_header[type],
        start_date: startDateEligibility,
        end_date: endDateEligibility,
      };
      this.$router.push({
        name: "BridgeCourseTimePeriodDetail",
        params: {
          eligibility_time_period_detail: eligibility_time_period_detail,
        },
      });
    },
    showTreeBox() {
      return
    },
    refreshData(){
      this.searchByDate();
      this.selected_city = [];
    },
  },
  watch:{
    selected_city: function(val){
      this.city_with_gm = [];
      this.selected_spoc = [];
      this.selected_gms = []
      let city_classifications = []
      let city_wise_gm = []
      this.selected_gm_cc = []
      if(this.selected_city.length != 0){
        for (let i = 0; i < this.spoc_data.length; i++) {
          const element = this.spoc_data[i];
          for (let j = 0; j < val.length; j++) {
            const selectCity = val[j];
            if (element.city == selectCity) {
              city_classifications.push(element);
          this.city_with_gm = [];
            }
          }
        }
          city_wise_gm.push(
            ...new Set(
              city_classifications.map(
                (item) => `${item.city_classification}-${item.GM}`
              )
            )
          )
          for (let i = 0; i < city_wise_gm.length; i++) {
            const data = city_wise_gm[i];
            const obj = {
                id: data,
                label: data,
              };
              let k = obj.label.indexOf("-")
              let l = obj.label.length
              if(obj.label.substring(k+1,l) != 'null'){
                this.city_with_gm.push(obj)
              }
          }
      
      }
      this.mergeAllSearch();
    },
    selected_gm_cc:function(val){
      var gm_name = null;
      this.selected_spoc = []
      this.selected_gms = []
      this.city_with_sr = []
      let gms = []
      if(this.selected_gm_cc.length !=0){
        for (let i = 0; i < this.spoc_data.length; i++) {
            const element = this.spoc_data[i]
            for (let index = 0; index < val.length; index++) {
            const details = val[index];
            let gm_split = details.split("-");
            let cc =  gm_split[0].trim();
            gm_name = gm_split[1].trim();
            if(element.city_classification == cc && element.GM == gm_name){
              gms.push(gm_name)
              const obj = {
                id: element.spoc_name,
                label: element.spoc_name,
              };
              this.city_with_sr.push(obj)
            }
          }
      }
      this.removeDuplicates(gms)
      this.mergeAllSearch();
    }
  },
  selected_spoc(selected_spoc) {
      if (selected_spoc == [] || selected_spoc == null) {
        this.selected_spoc = []
      }
      this.mergeAllSearch();
    },
}
};
</script>
<style scoped>
.card-header {
  text-align: start;
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.key_matric_heading {
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
}
.top_spoc_heading{
  background: #0044ba;
  border-radius: 15px 15px 0px 0px;
  padding-block: 10px;
}

.selectSource {
  width: 100%;
  margin-top: 0px;
  background-color: grey;
  color: black !important;
}
.vs__dropdown-menu {
  color: black;
}

.vs__dropdown--menu {
  width: 300px;
}

.a-iconn {
  padding: 0 10px 0 10px;
  height: 36px;
  outline: none;
  text-decoration: none !important;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #bfbfbf
}

.dropp-down {
  width: 100%
}

.a-iconn>.material-icons {
  margin-left: auto;
  color: #bfbfbf
}

.dropdown-cm {
  width: 15%
}
.main-headd{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin-top:1%
}
@media only screen and (max-width: 1425px) {
    .main-headd{
      font-size:16px;
      margin-top:10px
    }
}
</style>